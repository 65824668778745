import React, { useState, useCallback, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPaperPlane,
  faChevronDown,
  faGlobe,
  faGraduationCap,
  faCalculator,
  faPenFancy,
  faVideo,
  faComments,
} from '@fortawesome/free-solid-svg-icons';
import './styles/Chatinput.css';

const options = [
  { icon: faGlobe, title: 'Adsecura', description: 'Guides for Adsecura App' },
  { icon: faGraduationCap, title: 'DV360', description: 'Display & Video 360 Support' },
  { icon: faCalculator, title: 'CM360', description: 'Campaign Manager 360 Support' },
  { icon: faPenFancy, title: 'SA360', description: 'Search Ads 360 Support' },
  { icon: faVideo, title: 'Tag Manager', description: 'Tag Manager Assistance' },
  { icon: faComments, title: 'GA4', description: 'Google Analytics 4 Assistance' },
  { icon: faGlobe, title: 'Amazon DSP', description: 'Amazon DSP Support' },
];

const ChatInput = ({ userInput = '', handleInputChange, handleSendClick }) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]); // Default to the first option

  const textareaRef = useRef(null);

  const handleInputKeyDown = useCallback((event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendClick({ type: 'text', payload: userInput });
    }
  }, [handleSendClick, userInput]);

  const onSendClick = () => {
    handleSendClick({ type: 'text', payload: userInput });
  };

  // Function to resize textarea based on content
  const resizeTextarea = () => {
    const textarea = textareaRef.current;
    textarea.style.height = 'auto'; // Reset height
    textarea.style.height = `${textarea.scrollHeight}px`; // Adjust height to scrollHeight
  };

  // Trigger resize on input change
  useEffect(() => {
    resizeTextarea();
  }, [userInput]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setDropdownVisible(false);
  };

  return (
    <div className="chat-input">
      <div className="input-container">
        {/* Wrap dropdown toggle and textarea in a single container */}
        <div className="dropdown-and-input">
          <button
            className="dropdown-toggle"
            onClick={() => setDropdownVisible(!isDropdownVisible)}
          >
            <FontAwesomeIcon icon={selectedOption.icon} className="selected-icon" />
            {selectedOption.title}
            <FontAwesomeIcon icon={faChevronDown} className="dropdown-icon" />
          </button>

          <textarea
            ref={textareaRef}
            value={userInput}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            className="chatbot-input"
            placeholder="Chat with ADAM..."
            rows={1}
            style={{ overflow: 'hidden' }}
          />
        </div>

        {/* Options Dropdown Menu */}
        {isDropdownVisible && (
          <div className="dropdown-menu">
            <div className="dropdown-content">
              {options.map((option, index) => (
                <div
                  className="dropdown-item"
                  key={index}
                  onClick={() => handleOptionClick(option)}
                >
                  <FontAwesomeIcon icon={option.icon} className="dropdown-item-icon" />
                  <div className="dropdown-item-text">
                    <div className="dropdown-item-title">{option.title}</div>
                    <div className="dropdown-item-description">{option.description}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        <button
          type="button"
          onClick={onSendClick}
          className="chatbot-send-button"
          disabled={!userInput.trim()}
        >
          <FontAwesomeIcon icon={faPaperPlane} />
        </button>
      </div>
    </div>
  );
};

export default ChatInput;
